import { createContext, useContext } from 'react';

export const LangContext = createContext({
    lang: 'en',
    setLang: () => {},
});

export default function useCurrentLang(){

    const { lang } = useContext(LangContext);
    return lang || 'en'
}

function useCurrentLangWithSet(){

    const { lang, setLang } = useContext(LangContext);
    return [lang || 'en', setLang]
}

export { useCurrentLangWithSet }