import { Helmet } from 'react-helmet-async'

const getFullAltPath = ({pathname}, altlang )=> `https://kiteglobing.com/${altlang}`
const langArr = ['en','de','fr']

export default function withHelmet({loc, title, metaDesc}){
    return <Helmet htmlAttributes={{ lang: loc }}>
    {
        langArr.map((item, index) => item !== loc && <link key={`hfl-${index}`} rel="alternate" hreflang={`${item}`} href={getFullAltPath(window.location, item)} />)
    }
    <title>{title}</title>
    <meta name="description" content={`${metaDesc}`} />
  </Helmet>
}