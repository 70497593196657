import './Home.css';
import { useEffect } from 'react';
import data from './Home.yaml'
import HomeSection from './Components/HomeSection'
import useCurrentLang from './Components/useCurrentLang';
import withHelmet from './Components/withHelmet';

function Home() {

  const loc = useCurrentLang()

  const fn = function() {
    var lazyloadImages;    
    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll(".lazy");
      var imageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var image = entry.target;
            image.src = image.dataset.src;
            image.classList.remove("lazy");
            imageObserver.unobserve(image);
          }
        });
      });
  
      lazyloadImages.forEach(function(image) {
        imageObserver.observe(image);
      });
    }
  }

  useEffect(()=>{
    fn();
  }, [])

  
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title

  return (
    <div>
        
        {withHelmet({loc, title:yml.title, metaDesc})}
        
        <section className="home-section-content" >
          <div className="home-center home-center-intro" >
            <div style={{flex:'1 0 auto', maxWidth:'100%', position:'relative'}}>
              <picture>
                <source media="(max-aspect-ratio: 1/1)" srcSet="/img/hp_belombre_center-700w.webp" />
                <img width="100%" height="auto" src="/img/hp_belombre_center.webp" alt="kitesurf center" />
              </picture>
              {/* <img src="/img/arrow_downward_black_24dp.svg" alt="arrow down" style={{position:'absolute', bottom:-50, left:'50%', width:50, heigh:50, opacity:.5}} /> */}
              <div className="heromsg-container" style={{}}>
                <h1 className="heromsg" style={{}}>{yml.heromsg}</h1>
              </div>
            </div>
          </div>
        </section>

        <HomeSection {...yml.watersport} imageSide="left" />
        <HomeSection {...yml.kitelesson} imageSide="right" />
        <HomeSection {...yml.kiterental} imageSide="left" />
        <HomeSection {...yml.storage} imageSide="right" />
        <HomeSection {...yml.junior} imageSide="left" />
        <HomeSection {...yml.windsurf} imageSide="r" />
        <HomeSection {...yml.surfing} imageSide="l" />
        <HomeSection {...yml.wingsurfing} imageSide="r" />
        <HomeSection {...yml.sailing} imageSide="l" />
        <HomeSection {...yml.sup} imageSide="r" />
        <HomeSection {...yml.boat} imageSide="l" />
        <HomeSection {...yml.hotels} imageSide="r" />


    </div>
  );
}

export default Home;

