import { nanoid } from 'nanoid'
import BtnReadMore from './BtnReadMore/BtnMore'
import useCurrentLang from './useCurrentLang';
import { useState } from "react";
import {processItemWOnClick} from './others'

export default function HomeSection({image, imageAlt, heading, text, cta, imageSide, popup, popupTitle}){
    const [open, setOpen] = useState(false);
    const loc = useCurrentLang()
    const isLeft = imageSide==="left" || imageSide==="l"
    const isRight = imageSide==="right" || imageSide==="r"

    // const processItem = item => {
    //    const regexpNames = /(?<text>(.*)) {{(?<link>(.*))}}(?<rest>(.*))/gm;
    //    const r1 = item.match(regexpNames)
    //    if(r1==null){ return item}
    //    return <>
    //     {Array.from(item.matchAll(regexpNames)).map( a => <Fragment key={`${Math.random()}`}>{a.groups.text} <span onClick={a.groups.link.indexOf('href')?()=>{setOpen(true)}:()=>{}} dangerouslySetInnerHTML={{ __html: a.groups.link }}></span>{a.groups.rest}</Fragment>)}
    //    </>
    // }

    return(
        <section className="section-content" >
            <div className={`section-center ${isRight ? 'section-reverse':''}`}>
          
                {isLeft && <div className="flex-with-img" >
                    <figure>
                        <img className="lazy" width="100%" height="auto" data-src={`/img/${image}`} alt={`${imageAlt}`} />
                    </figure>
                </div>}

                <div className="flex-with-text" >
                    <div className="section-center-text">
                    <h2>{heading}</h2>
                    {
                        text.map( item => <p key={nanoid(6)}>{processItemWOnClick(item, setOpen)}</p>)
                    }
                    {
                        cta.map( cta => <p key={nanoid(6)}><BtnReadMore to={`/${loc}${cta[0]}`} msg={cta[1]} /></p>)
                    }
                    </div>
                </div>

                {isRight && <div className="flex-with-img" >
                    <figure>
                        <img className="lazy" width="100%" height="auto" data-src={`/img/${image}`} alt={`${imageAlt}`} />
                    </figure>
                </div>}
              
            </div>
        </section>


    )
}