import { Fragment } from 'react'
import useCurrentLang from './useCurrentLang';
import data from './Popup.yaml'

const ReadMore = setOpen => {
    const loc = useCurrentLang();
    const yml = data[loc] || data.en;
    return <a onClick={()=>setOpen(true)} style={{color:'blue', cursor:'pointer', display:'flex',gap:10, alignItems:'center'}}><span>{yml.readmore}&nbsp;</span><img style={{width:140, height:'auto'}} src="/img/credits/safetytool-logo.png" alt="SafetyTool logo" width={926} height={210} /></a>
}

const processItem = (item, setOpen) => {
    if(item.startsWith('{{popup}}')) return <>{ReadMore(setOpen)}</>
    const regexpNames = /(?<text>(.*)){{(?<link>(.*))}}(?<rest>(.*))/gm;
    const r1 = item.match(regexpNames)
    if(r1==null){ return <p dangerouslySetInnerHTML={{ __html: item }}></p>}
    //    item }
    
    return <>
     {Array.from(item.matchAll(regexpNames)).map( a => <Fragment key={`${Math.random()}`}
        ><span dangerouslySetInnerHTML={{ __html: a.groups.text }}></span><span onClick={setOpen && a.groups.link.indexOf('href')?()=>{setOpen(true)}:()=>{}} dangerouslySetInnerHTML={{ __html: a.groups.link }}></span><span dangerouslySetInnerHTML={{ __html: a.groups.rest }}></span></Fragment>)}
    </>
}

const processItemWOnClick = (item, setOpen) => {
    const regexpNames = /(?<text>(.*)){{(?<link>(.*))}}(?<rest>(.*))/gm;
    const r1 = item.match(regexpNames)
    if(r1==null){ return item }
    return <>
     {Array.from(item.matchAll(regexpNames)).map( a => <Fragment key={`${Math.random()}`}
        >{a.groups.text} <span onClick={setOpen && a.groups.link.indexOf('href')?()=>{setOpen(true)}:()=>{}} dangerouslySetInnerHTML={{ __html: a.groups.link }}></span>{a.groups.rest}</Fragment>)}
    </>
}

export { processItem, processItemWOnClick }