import { Helmet } from 'react-helmet-async';
import Prices from '../Prices/Prices';
import useCurrentLang from '../useCurrentLang';
import PageHeader from '../PageHeader/PageHeader';
import { PageSectionImg, PageSectionContent } from './PageSections';

export function WithPage(data, pricesKey, otherPricesKey) {

  const loc = useCurrentLang();
  const yml = data[loc] || data.en;
  const metaDesc = yml.metaDescription || yml.title;

  return (
    <div>
      <Helmet>
        <title>{yml.title}</title>
        <meta name="description" content={`${metaDesc}`} />
      </Helmet>
      <PageHeader pageTitle={yml.pageTitle}></PageHeader>
      <PageSectionImg image={yml.image} imageAlt={yml.imageAlt} />
      <PageSectionContent content={{...yml.content,...data.all}} />
      <section className="section-content" style={{ paddingTop: 0, marginTop: 0 }}>
        <Prices ymlkey={`${pricesKey}`} hideFullPriceList={!!otherPricesKey} showPrepay={!!otherPricesKey} />
        {otherPricesKey && <Prices ymlkey={`${otherPricesKey}`} hideTopBookByEmail={true} showPrepay={true} />}
      </section>
    </div>
  );

}
