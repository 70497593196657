import React from "react";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTopHook() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo(0, 0);
    // for safari
    //setTimeout(function () { window.scrollTo(0, 0); }, 100);

  }, [pathname]);

  return null;
}

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if ( this.props.location.pathname !== prevProps.location.pathname ) {
      
      // console.log('PN: ', this.props.location.pathname)
      // console.log('PNprev: ', prevProps.location.pathname)
      window.scrollTo(0, 0);
      // for safari
      // setTimeout(function () { window.scrollTo(0, 0); }, 100);

    }
  }

  render() {
    return null;
  }
}

const ScrollToTopClassic = withRouter(ScrollToTop)
export {  ScrollToTopClassic };