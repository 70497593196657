import useCurrentLang from '../Components/useCurrentLang';
import dataLesson from './KitesurfingLesson.yaml'
import dataRental from './KitesurfingRental.yaml'
import { Helmet } from 'react-helmet-async'
import Prices from '../Components/Prices/Prices'
import PageHeader from '../Components/PageHeader/PageHeader';
import { PageSectionImg, PageSectionContent } from '../Components/PageSections/PageSections';
import YouMightPrefer from '../Components/YouMightPrefer';

function Kitesurfing({type}) {

  const data =  type === "rental" ? dataRental : dataLesson   
  const loc = useCurrentLang();
  const yml = data[loc] || data.en;
  const metaDesc = yml.metaDescription || yml.title;

  return (
      
    <div>

        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>

        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        <PageSectionImg image={yml.image} imageAlt={yml.imageAlt} imgStyle={{maxWidth:1200}} />
        <PageSectionContent content={{...yml.content,...data.all}} />

        <section className="section-content" style={{paddingTop:0, marginTop:0}}>
        {
            type === "rental"
            ? <Prices ymlkey="kitesurfrental" showPrepay={true}/>
            : <Prices ymlkey="kitesurflesson" showPrepay={true}/>
        }
        </section>

        <YouMightPrefer caption={yml.youmightprefer.caption} url={yml.youmightprefer.url} msg={yml.youmightprefer.msg} />

    </div>
  );
}


export default Kitesurfing;