import './Footer.css'
import data from './Footer.yaml'
import { Link } from "react-router-dom";
import useCurrentLang from './Components/useCurrentLang';

function Footer() {

    const lang = useCurrentLang()
    const yml = data[lang] || data.en
    let copyYear = (new Date()).getFullYear()

    const {aboutus, partners, followus, links, copy, allrights, privacy, terms} = yml
    const onClick = ()=>{document.getElementById('my-check').checked = false;}

    // let allmylabels = []
    
    // const pp = obj => {
    //     for (const [key, value] of Object.entries(obj)) {
    //         if(key=="label" || key=="title"){ allmylabels = [...allmylabels, value] }
    //         else if(value !=null  && typeof value =='object'){
    //             pp(value)
    //         }
    //     }
    // }

    // pp(yml)
    // console.log("ALLfooter",allmylabels)

    return(
        <footer>
            <div className="container" >
                <div>
                    <ul>
                        <li>{aboutus.title}</li>
                        <li><Link to={`/${lang}${aboutus.video.url}`} onClick={onClick}>{aboutus.video.label}</Link></li>
                        <li><Link to={`/${lang}${aboutus.press.url}`} onClick={onClick}>{aboutus.press.label}</Link></li>
                        <li><a href={`${links.weather.url}`} target="_blank" rel="noopener noreferrer">{links.weather.label}</a></li>
                        <li><a href={`${links.mauritius.url}`} target="_blank" rel="noopener noreferrer">{links.mauritius.label}</a></li>
                        <li><Link to={`/${lang}${aboutus.contact.url}`} onClick={onClick}>{`${aboutus.contact.label}`}</Link></li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>{partners.title}</li>
                        <li><Link to={`/${lang}${partners.villas.url}`} onClick={()=>{document.getElementById('my-check').checked = false;}}>{`${partners.villas.label}`}</Link></li>
                        <li><a href="https://www.kitereisen.com/" target="_blank" rel="noopener noreferrer">Kitereisen.com</a></li>
                        <li><a href="https://www.sunandfun.com/kiten/Orte/Mauritius/" target="_blank" rel="noopener noreferrer">Sunandfun.com</a></li>
                        <li><a href="https://www.planettravelholidays.com/" target="_blank" rel="noopener noreferrer">Planet Travel Holidays</a> </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>KITEGLOBING (MAURITIUS) & MORE LTD</li>
                        <li>C Beach Club</li>
                        <li>Bel Ombre, Mauritius</li>
                        <li>T: (+230) 605 5334</li>
                        <li>M: (+230) 5717 5348</li>
                        <li>e-mail: info@kiteglobing.com</li>
                    </ul>
                    <ul className="sub-follow-us">
                        <li>{followus.title}</li>
                        <li>
                            <a href="https://www.facebook.com/KiteGlobing-217243858294399/" target="_blank" rel="noopener noreferrer"><img width="50px" height="50px" className="footer-social-img" src="/img/facebook.webp" alt="KiteGlobing facebook" /></a>
                            <a href="https://www.instagram.com/kiteglobing/" target="_blank" rel="noopener noreferrer"><img width="50px" height="50px" className="footer-social-img" src="/img/instagram.webp" alt="KiteGlobing instagram" /></a>
                        </li>
                    </ul>
                </div>
                <div className="follow-us">
                    <ul>
                        <li>{followus.title}</li>
                        <li>
                            <a href="https://www.facebook.com/KiteGlobing-217243858294399/" target="_blank" rel="noopener noreferrer"><img className="footer-social-img" src="/img/facebook.webp" alt="KiteGlobing facebook" /></a>
                            <a href="https://www.instagram.com/kiteglobing/" target="_blank" rel="noopener noreferrer"><img className="footer-social-img" src="/img/instagram.webp" alt="KiteGlobing instagram" /></a>
                        </li>
                    </ul>
                </div>
                <div className="subfooter">
                    <img className="center" src="/img/KiteGlobingLogo-transp.webp" width="140px" alt="KiteGlobing footer logo" />
                    <p style={{fontSize:'0.9em', textTransform:'uppercase'}}>{copy.title} {copyYear} KITEGLOBING KITE & MORE LTD - {allrights.title}.</p>
                    <p style={{fontSize:'0.7em', fontFamily:'Raleway'}}><a className="legal-links" href={`/${lang}${privacy.url}`}>{privacy.title}</a> | <a className="legal-links" href={`/${lang}${terms.url}`}>{terms.title}</a> </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;