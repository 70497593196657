import { nanoid } from 'nanoid'
import BtnReadMore from '../BtnReadMore/BtnMore'

export default function CenterSection({imgOnTheRight, image, imageAlt, heading, text, cta, loc}){

    return(
        <section className="section-content" >
            <div className={`kite-center ${imgOnTheRight ? 'section-reverse': ''}`}>

                {imgOnTheRight && <div style={{flex:'1 0 auto'}}>
                    <div className="kite-center-text ">
                    <h3>{heading}</h3>
                    {text.map( item => <p key={nanoid(6)}>{item}</p>)} 
                    {!!cta && <p><BtnReadMore to={`/${loc}${cta.to}`} msg={cta.msg} /></p>}
                    </div>
                </div>}

                <div className="flex-with-img">
                    <figure>
                    <img width="100%" height="auto" src={`/img/${image}`} alt={imageAlt} />
                    </figure>
                </div>
                
                {!imgOnTheRight && <div style={{flex:'1 0 auto'}}>
                    <div className="kite-center-text">
                    <h3>{heading}</h3>
                    {text.map( item => <p key={nanoid(6)}>{item}</p>)} 
                    {!!cta && <p><BtnReadMore to={`/${loc}${cta.to}`} msg={cta.msg} /></p>}
                    </div>
                </div>}
            </div>
        </section>

    )

}