import data from './StorageRescue.yaml'
import { Helmet } from 'react-helmet-async'
import Prices from '../Components/Prices/Prices'
import PageHeader from '../Components/PageHeader/PageHeader';
import useCurrentLang from '../Components/useCurrentLang';
import { PageSectionImg, PageSectionContent } from '../Components/PageSections/PageSections';

function StorageRescue() {

  const loc = useCurrentLang();
  const yml = data[loc] || data.en;
  const metaDesc = yml.metaDescription || yml.title;

  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        <PageSectionImg image={yml.image} imageAlt={yml.imageAlt} imgStyle={{maxWidth:1200}} />
        <PageSectionContent content={{...yml.content,...data.all}} />
        
        <section className="section-content" style={{paddingTop:0, marginTop:0}}>
          <Prices ymlkey="rescue-storage" showPrepay={true} />
        </section>

    </div>
  );
}

export default StorageRescue;