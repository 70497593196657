// import { Fragment } from 'react'
import data from './WatersportCenter.yaml'
import { nanoid } from 'nanoid'
import { Helmet } from 'react-helmet-async'
import useCurrentLang from '../Components/useCurrentLang';
import usePhGallery from '../Components/withPhGallery';
import PageHeader from '../Components/PageHeader/PageHeader';
import CenterSection from '../Components/CenterSection/CenterSection';
import { processItem } from '../Components/others'

function WatersportCenter() {

  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title

  /* TO BE CHECKED
  const fn = function() {
    var lazyloadImages;    
    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll(".lazy");
      var imageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var image = entry.target;
            image.src = image.dataset.src;
            image.classList.remove("lazy");
            imageObserver.unobserve(image);
          }
        });
      });
  
      lazyloadImages.forEach(function(image) {
        imageObserver.observe(image);
      });
    }
  }

  useEffect(()=>{
    console.log('lazy')
    fn();
  }, [])
  */

//   const processItem = item => {
//     const regexpNames = /(?<text>(.*)) {{(?<link>(.*))}}(?<rest>(.*))/gm;
//     const r1 = item.match(regexpNames)
//     if(r1==null){ return item}
//     return <>
//      {Array.from(item.matchAll(regexpNames)).map( (a, i) => <Fragment key={`wc_${i}`}>{a.groups.text} <span dangerouslySetInnerHTML={{ __html: a.groups.link }}></span>{a.groups.rest}</Fragment>)}
//     </>
//  }

  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>

        <section className="section-content section-content-first" >
          <div className="kite-center kite-center-intro">
            <div style={{flex:'1 0 auto'}}>
              <div className="kite-center-text">
                <h2>{yml.heading}</h2>
                {yml.text.map( item => <p key={nanoid(6)}>{processItem(item)}</p>)}  
                <p>
                </p>
              </div>
            </div>
            <div style={{flex:'1 0 50%', height:720}}>
              <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/gExgXquFHT4?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="KiteGlobing" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </section>

        <CenterSection {...yml.belombre} imgOnTheRight={false} loc={loc} />
        <CenterSection {...yml.others} imgOnTheRight={true} loc={loc} />
        
        <section className="section-content">
          <div style={{margin:'auto 0px',display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
            <p style={{fontFamily:'Raleway',fontSize:'1.2rem', fontWeight:'bold', color:'var(--black-type)', marginLeft:10, marginRight:10}}>{yml.checkgallery}</p>
            <p style={{marginLeft:10, marginRight:10}}><a className="btn-read-more" href={`/${loc}/our-video`} style={{textDecoration:'none', color:'var(--black-type)'}}>{yml.watchvideo}</a></p>
          </div>
         {usePhGallery(yml.gallery, '/img/gallery')}
        </section>



    </div>
  );
}

export default WatersportCenter;