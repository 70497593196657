import data from './Events.yaml'
import { nanoid } from 'nanoid'
import { Helmet } from 'react-helmet-async'
import useCurrentLang from '../Components/useCurrentLang';
import PageHeader from '../Components/PageHeader/PageHeader';

export default function Events() {

  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title

  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>

        <section className="section-content section-content-first" >
          <div className="kite-center kite-center-intro">
            <div style={{flex:'1 0 100%'}}>
              <div className="kite-center-text">
                <h2>{yml.heading}</h2>
                {yml.text.map( item => <p key={nanoid(6)}>{item}</p>)}  
                <p>
                </p>
              </div>
            </div>
            <div style={{flex:'1 0 100%'}}>
                <img width="100%" height="auto" src={`/img/${yml.image}`} alt={`${yml.imageAlt}`}/>
            </div>
          </div>
        </section>

    </div>
  );
  
}
