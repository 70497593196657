import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid'
import useMediaQuery from '../Components/Altro/mediaQuery'
import PhGallery from '../Components/PhotoGallery/PhGallery'

function usePhGallery(gallery, baseUrl){

    const [isShown, showGallery] = useState(false);
    const [ isOneCol , setMQ ]= useMediaQuery("screen and (max-width: 600px)");

    useEffect(()=>{
        document.body.style.overflow= isShown ? 'hidden' : 'auto'
    },[isShown])

    useEffect(()=>{
        if(isOneCol)showGallery(false)
    },[isOneCol])

    return(
            <PhGallery key={nanoid(6)} gallery={gallery} baseUrl={baseUrl} actions={[showGallery]} isOneCol={isOneCol} isShown={isShown} />
    )
    
    
}

export default usePhGallery