import './PageHeader.css'

export default function PageHeader({pageTitle}){


    return(
        <section className="page-header" >
            <div className="page-header-inner">
            <div className="page-header-text">
                <h1>{pageTitle}</h1>
            </div>
            </div>
        </section>
    )
}