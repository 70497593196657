import { nanoid } from 'nanoid'
import Carousel from '../Carousel/Carousel'
import {useRef} from 'react'

let slides = []
let index = 0

export default function PhGallery({actions, isOneCol, gallery, baseUrl, isShown}){

    const [showGallery] = actions
    const myGallery = useRef(null);

    function _setSlides(fname){
        const gallery = myGallery.current;//document.getElementById('my-gallery')
        let allcols = gallery.querySelectorAll('.ph-column')
        let arrSrc = []
        let rest = []
        const flbase = window.getComputedStyle(allcols[0]).flexBasis
        
        let arrSrcNew =[]
        for(const colItem of Array.from(allcols)){
          arrSrcNew = [...arrSrcNew, [...Array.from(colItem.querySelectorAll('img')).map(img => img.src)]]
        }
        rest = arrSrcNew
        if(flbase === '50%') rest = [[...arrSrcNew[0], ...arrSrcNew[2]], [...arrSrcNew[1], ...!!arrSrcNew[3] ? arrSrcNew[3] : null]];
        if(flbase === '100%') rest = [[...arrSrcNew[0], ...arrSrcNew[1], ...arrSrcNew[2], ...!!arrSrcNew[3] ? arrSrcNew[3] : null]];
        // console.log('REST', rest)

        // col by col
        // Array.from(allcols).map((item)=>{
        //   let allimgs = item.querySelectorAll('img')
        //   allimgs.forEach( img => arrSrc = [...arrSrc, img.src])
        // })

        // row by row
        // const lArr = Array.from(allcols).map(item=> item.querySelectorAll('img').length)
        // const max = lArr.reduce((curr, acc) => curr > acc ? curr : acc)
        // const maxArr = [...Array(max).keys()]
        // const arrArr = Array.from(allcols).map( item=> Array.from(item.querySelectorAll('img')).map(img => img.src))
        // for(const index in maxArr){
        //   arrSrc = [...arrSrc, ...Array.from(arrArr).map( item => !!item[index] ? item[index] : null)]
        // }

        // row by row -- NEW!
        const lArr = rest.map(item=> item.length)
        const max = lArr.reduce((curr, acc) => curr > acc ? curr : acc)
        const maxArr = [...Array(max).keys()]
        const arrArr = rest.map( item => item.map(img => img))
        for(const index in maxArr){
          arrSrc = [...arrSrc, ...arrArr.map( item => !!item[index] ? item[index] : null)]
        }
        slides = arrSrc.filter(item => item)
        const findIndex = item => item.indexOf(fname) > 0
        const r = slides.findIndex(findIndex)
        //console.log("FINDIDX", r)
        index = r === -1 ? 0 : r
    }

    // function _setSlidesHoriz(fname){
      
    //   const gallery = document.getElementById('my-gallery')
    //   let allcols = gallery.querySelectorAll('.ph-column')
    //   let arrSrc = []

    //   const lArr = Array.from(allcols).map(item=> item.querySelectorAll('img').length)
    //   const max = lArr.reduce((curr, acc) => curr > acc ? curr : acc)

    //   Array.from(allcols).map((item)=>{
    //       let allimgs = item.querySelectorAll('img')
    //       allimgs.forEach( img => arrSrc = [...arrSrc, img.src])
    //   })
    //   slides = arrSrc
    //   const findIndex = item => item.indexOf(fname) > 0
    //   const r = arrSrc.findIndex(findIndex)
    //   // console.log("FINDIDX", r)
    //   index = r ==-1 ? 0 : r
    // }

    let path = baseUrl || '/img'

    return (
      <>
      <div className="ph-row" id={`my-gallery${nanoid(6)}`} ref={myGallery}> 
        {
          gallery.map(item =><div key={nanoid(6)} className="ph-column">
          {
            item.map(img => isOneCol ? <img key={nanoid(6)}  alt=""  onClick={() => {_setSlides(img); showGallery(true);}} src={`${path}/${img}`} />
                                        : <img key={nanoid(6)} alt="" style={{cursor:'pointer'}} onClick={() => {_setSlides(img); showGallery(true);}} src={`${path}/${img}`} />)
          }
          </div>)
        }
      </div>
      {/* {isShown && !isOneCol && <Carousel imgs={slides} index={index} chiudi={()=>showGallery(false)} />} */}
      {isShown && <Carousel imgs={slides} index={index} close={()=>showGallery(false)} />}
      </>
    )
  }