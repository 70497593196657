import './Credits.css'
import { nanoid } from 'nanoid'
export default function Credits(){

    const creditslogo = ['CORE_black.png', 'vdws.png','ion-logo.png','tag-heuer-logo.png', 'heritage-resorts-logo.png']
    const logolinks = ['https://ridecore.com/', 'https://www.vdws.de/','https://www.ion-products.com/','', 'https://heritageresorts.mu/']
    return (

        <div className="credits">

            {
                creditslogo.map( (item, idx) => <div key={nanoid(6)} style={{flex:1}}>
                    {!!logolinks[idx] ? <a href={`${logolinks[idx]}`} style={{color:'transparent',textDecoration:'none'}} target='_blank'><img style={{display:'block', margin:'auto'}} width="150px" height="auto" src={`/img/credits/${item}`} alt={`${item}`} /></a>
                     :<img style={{display:'block', margin:'auto'}} width="150px" height="auto" src={`/img/credits/${item}`} alt={`${item}`} />}
                </div>)
            }

        </div>
    )
}