import data from './Accomodation.yaml'
import { nanoid } from 'nanoid'
import { Helmet } from 'react-helmet-async'
import useCurrentLang from '../Components/useCurrentLang';
import PageHeader from '../Components/PageHeader/PageHeader';
import { PageSectionImg, PageSectionContentChild } from '../Components/PageSections/PageSections';
import usePhGallery from '../Components/withPhGallery';

function subcaption(text){
  if(text.toString().includes('http')){
    let words = text.split(' ')
    let newwords = words.map(word => {
      if(word.toString().startsWith('http')){ 
        return <a target='_blank' href={`${word}`} rel="noreferrer" >{word}</a>
      }
      return word
    })
    let newtext = newwords.filter(s=>typeof s !== 'object').join(' ') + ' '
    let result = [newtext, ...newwords.filter(s=>typeof s === 'object')]
    return <>
    {result.map(w => w)}
    </>
  }
  return text
}

function subcaption2(text){
  subcaption3(text.toString())
  if(text.toString().includes('http')){
    let words = text.split(' ')
    let newwords = words.map(word => {
      if(word.toString().startsWith('http')){ 
        return `<a target="_blank" href="${word}">${word}</a>`
      }
      return word
    })
    let newtext = newwords.join(' ')
    return <span dangerouslySetInnerHTML={{__html: newtext}}></span>
  }
  return text
}

function subcaption3(text){
  // if (typeof text === 'string') {
  //   console.log(typeof text)
  // }
  // else{
  //   console.log('not a string', typeof text)
  // }
  // console.log(text)
    // let matches = text.match(/\[(.*)\]\((.*)\)/)
    // console.log('matches',matches)
    // let newstr = text.replaceAll(/\[(.*)\]\((.*)\)/g, '<a target="_blank" href="$2">$1</a>')
    // console.log(newstr)
    //return text.toString().includes('http') ? text.replaceAll(/\[(.*)\]\((.*)\)/g, '<a target="_blank" href="$2">$1</a>') : text
    return <span dangerouslySetInnerHTML={{__html: text.replace(/\[(.*)\]\((.*)\)/g, '<a style="color:blue" target="_blank" href="$2" rel="noreferrer" >$1</a>')}}></span>
}

function AccGallery({ gallery, imgpath }) {
  return <section key={nanoid(6)} className="section-content" style={{ marginTop: 0 }}>
    <div style={{ margin: 'auto 30px', display: 'flex', flexWrap:'wrap', justifyContent: 'center', alignItems: 'center' }}>
      <p style={{ fontFamily: 'Raleway', fontSize: '1.2rem', fontWeight: 'bold', color: 'var(--black-type)' }}>{gallery.caption}</p>
      {!!gallery.subcaption && <p style={{fontFamily: 'Raleway', fontSize: '1.0rem', fontWeight: 'bold', color: 'var(--black-type)' }}>{subcaption3(gallery.subcaption)}</p>}
    </div>
    {usePhGallery(gallery.items, imgpath)}
  </section>;
}

export default function Accomodation() {

  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title

  const aStyle = {textDecoration:'none', color:'blue'}
  
  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        
        <PageSectionImg image={yml.image} imageAlt={yml.imageAlt} />
        <PageSectionContentChild content={yml.content} >
          {
            (content)=><>
            {content.text.map( item => <p key={nanoid(6)} dangerouslySetInnerHTML={{__html: item}}></p>)} 
                {content.headings.map( item => <div key={nanoid(6)}>
                  <h3 style={{textAlign:'center', fontSize:30}}>{item.heading}</h3>
                  {item.text.map( txt => typeof txt == "string" ? !!txt && <p key={nanoid(6)} dangerouslySetInnerHTML={{__html: txt}}></p> : 
                  typeof txt == "object" && Array.isArray(txt) ?
                    <p key={nanoid(6)}><a style={aStyle} href={txt[1]} target="_blank" rel="noreferrer">{txt[0]}</a></p>
                    : 
                    <p key={nanoid(6)}><a style={aStyle} href={txt.url} target="_blank" rel="noreferrer">{txt.caption}</a></p>
                  )
                  }
                </div>) 
                }
            </>
          }
        </PageSectionContentChild>

        {
          [
            {gallery:yml.gallery.morne, imgpath: '/img/la-gaulette'},
            {gallery:yml.gallery.apart1, imgpath: '/img/apart1'},
            {gallery:yml.gallery.apart2, imgpath: '/img/apart2'}
          ].map(AccGallery)
        }

    </div>
  );
}


