import data from './Video.yaml'
import { Helmet } from 'react-helmet-async'
import useCurrentLang from '../Components/useCurrentLang';
import PageHeader from '../Components/PageHeader/PageHeader';

export default function Video() {

  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title
  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        
        <section className="section-content" style={{position:'relative', display:'block'}}>
          <div className="kite-videos">
            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/gExgXquFHT4?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/9OrMBF0GuZM?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

          </div>
        </section>

        <section className="section-content" style={{position:'relative', display:'block', margin:'0px auto'}}>
          <div className="kite-videos">

            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/Koy4qTE3yeY?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/py5S2kwpPRI?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            
          </div>
        </section>

        <section className="section-content" style={{position:'relative', display:'block', margin:'0px auto'}}>
          <div className="kite-videos">

            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/jaUQKLOk_i4?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/Skc1fNQlFRU?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            
          </div>
        </section>

        <section className="section-content" style={{position:'relative', display:'block', margin:'0px auto'}}>
          <div className="kite-videos">

            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/mzB4lChJGzk?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/odagKncxmZ4?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            
          </div>
        </section>
    </div>
  );
}

