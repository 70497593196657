import data from './PrivacyPolicy.yaml'
import { Helmet } from 'react-helmet-async'
import PageHeader from '../Components/PageHeader/PageHeader';
import useCurrentLang from '../Components/useCurrentLang';
import { PageSectionContent } from '../Components/PageSections/PageSections';

export default function PrivacyPolicy() {

  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title

  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        <PageSectionContent content={yml.content}  />
        
    </div>
  );
}