import { useState } from "react";
import { nanoid } from 'nanoid'
import { processItem } from '../others'
import { Popup } from '../Popup'

import './PageSections.css'

export function PageSectionImg({image, imageAlt, imgStyle}) {
    const aStyle = !!imgStyle ? imgStyle : {}
    return <section className="section-content" style={{ marginTop: 0 }}>
      <div className="page-section page-section-img" >
        <div>
          <img width="100%" height="auto" style={aStyle} src={`/img/${image}`} alt={`${imageAlt}`} />
        </div>
      </div>
    </section>;
}

export function PageSectionContent({content, lang}) {
  //console.log(content)
  const [open, setOpen] = useState(false);
  const h2OnlyGerman = lang==='de' ? 'h2-only-german-mobile' : ''
  return <section className="section-content" style={{ marginTop: 0 }}>
    <div className="page-section page-section-content" >
      <div>
        <div className="page-section-inner-content">
          <h2 className={h2OnlyGerman}>{content.heading}</h2>
          <p>&nbsp;</p>
          {content.text.map(item => <p key={nanoid(6)}>{processItem(item, setOpen)}</p>)}
          {content.headings.map(item => <div key={nanoid(6)}>
            <h3>{item.heading}</h3>
            {item.text.map(txt => typeof txt == "string" ? !!txt && <p key={nanoid(6)}>{processItem(txt, setOpen)}</p> : 
              typeof txt == "object" && Array.isArray(txt) ? txt.map(t => <p key={nanoid(6)}>{processItem(t, setOpen)}</p>) :
              <p key={nanoid(6)}><b>{txt.caption}</b>
                <br />
                {typeof txt.text == "string" ? processItem(txt.text, setOpen) : txt.text.map(t => <span style={{display:'block'}} key={nanoid(6)}>{processItem(t, setOpen)}</span>)}
              </p>
            )}
          </div>)}
          <div>
          {open ? <Popup text={`SafetyTool`} closePopup={() => setOpen(false)} >{content.popupKey}</Popup> : null}
          </div>
        </div>
      </div>
    </div>
  </section>;
}

export function PageSectionContentChild({content, children}) {
  return <section className="section-content" style={{ marginTop: 0 }}>
    <div className="page-section page-section-content" >
      <div>
        <div className="page-section-inner-content">
          <h2>{content.heading}</h2>
          <p>&nbsp;</p>
          {children(content)}
        </div>
      </div>
    </div>
  </section>;
}
