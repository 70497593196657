import data from './Press.yaml'
import { Helmet } from 'react-helmet-async'
import PageHeader from '../Components/PageHeader/PageHeader';
import useMediaQuery from '../Components/Altro/mediaQuery'
import useCurrentLang from '../Components/useCurrentLang';

export default function Press({locale, type}) {

  const [ to600 , setMQ ]= useMediaQuery("screen and (max-width: 600px)");
  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title
  const pressUrl = url => url.startsWith('http') ? url : `/img/press/${url}`

  let caption = to600 ? {fontSize:20, marginTop:0, marginBottom:20} : {fontSize:30, marginTop:0, marginBottom:20}
  let pStyle = to600 ? {fontSize:16} : {fontSize:22}

  return (
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        
        <section className="section-content" style={{marginTop:20}}>
          <div className="kite-videos">
              <div style={{maxWidth:1280, margin:'0 auto'}}>
                  <div className="video-background" style={{height:720}}>
                    <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/kTtGN6B04HY?rel=0&amp;showinfo=0&amp;fs=1&amp;enablejsapi=0&amp;controls=1" title="KiteGlobing hallo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
              </div>
            </div>
        </section>
        
        <section className="section-content" style={{marginTop:20}}>
          <div className="kite-center kite-center-max-width" style={{marginTop:0, margin:'auto'}}>
            <div style={{flex:'1 0 100%'}}>
            <h3 style={{textAlign:'center', fontSize:30}}>{yml.content.heading}</h3>
              <div className="kite-content">
                {
                  yml.content.items.map( item => <div style={{display:'flex', flexWrap:'wrap' ,justifyContent:'center', alignContent:'space-around',gap:20, margin:'40px 0px'}}>
                    <div style={{flex:'1 0 100%'}}>
                        <img width="100%" height="auto" src={`/img/press/${item.image}`} alt={``} />
                    </div>
                    <div style={{flex:'1 0 100%', marginBottom:60}}>
                      <h3 style={caption}>{item.caption}</h3>
                      <p style={pStyle}>{item.text}</p>
                      <p style={{verticalAlign:'middle', marginTop:40}}><a href={pressUrl(item.url)} target="_blank" rel="noreferrer" style={{color:'#333', textDecoration:'none', border:'1px solid #333', padding:20,...pStyle}}>{yml.content.cta}&nbsp;<img style={{verticalAlign:'middle'}} src="/img/arrow_right_alt_black_24dp.svg" alt=""/></a></p>
                    </div>
                  </div>
                  )
                }
              </div>
            </div>
          </div>
        </section>

    </div>
  );
}

