import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";
import { nanoid } from 'nanoid'
import data from './Header.yaml'
import { useCurrentLangWithSet } from "./Components/useCurrentLang";
import './Header.css'

function merge(a, b) {
    return Object.entries(b).reduce((o, [k, v]) => {
        o[k] = v && typeof v === 'object'
            ? merge(o[k] = o[k] || (Array.isArray(v) ? [] : {}), v)
            : v;
        return o;
    }, a);
}

function Header() {

    const history = useHistory();
    const [lang, setLang] = useCurrentLangWithSet()
    const location = useLocation();
    const didMount = useRef(false)
    const changeLang = l => setLang(l);

    useEffect(()=>{
        if(didMount.current){
            // console.log('effect in header')
            history.push("/"+lang+location.pathname.substring(3))
        }
        else{
            didMount.current = true;
        }
    },[lang])
    const yml = data[lang] || data.en
    // const yml = merge(data.en, data[lang])

    const {home, watersport, kitesurfing, windsurfing, more, contact} = yml;
    const onClick = (ev)=>{ document.getElementById('my-check').checked = false; }
    const style = {textDecoration: 'none', color:'inherit' }
    const pointer = {cursor: 'pointer' }

    const getLocalizedPath = slug => !!lang ? `/${lang}${slug}` : `${watersport.url}`

    // let allmylabels = []
    
    // const pp = obj => {
    //     for (const [key, value] of Object.entries(obj)) {
    //         if(key=="label"){ allmylabels = [...allmylabels, value] }
    //         else if(value !=null  && typeof value =='object'){
    //             pp(value)
    //         }
    //     }
    // }

    // pp(yml)
    // console.log("ALL",allmylabels)

    return(
        <header key={location.key}>
            <nav className="navbar" >
                <a href={`/${lang}`} style={{paddingLeft:10}}><img src="/img/KiteGlobingLogo-transp.webp" width="180px" height="auto" alt="KiteGlobing header logo" /></a>
                <input id="my-check" type="checkbox" style={{position:'absolute',top:-9999, left:-9999}} />
                <label htmlFor="my-check" style={{position:'absolute', top:-9999, left:-9999}}></label>
                <ul className="nav-menu">
                        <li className="nav-item header-menu-title">
                            {/* <a href="/" style={{width:26, height:26}} aria-label="Home">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px" fill="#000000" style={{fill:'white', margin:'20px 0px 10px 0px'}}><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"></path></svg>
                            </a> */}
                            {/* <a id="skip-focus" rel="nofollow"/> */}
                            <Link to={`/${lang}`} onClick={onClick} style={style} >{home.label}</Link>
                        </li>
                        <li className="nav-item header-menu-title">
                            <Link to={getLocalizedPath(watersport.url)} onClick={onClick} style={style} >{watersport.label}</Link>
                        </li>
                        <li className="nav-item header-menu-title" tabIndex="0"><span>{kitesurfing.label}</span>
                            <ul className="nav-submenu" style={{height:'auto'}}>
                            {
                                kitesurfing.submenu.map(item => <li key={nanoid(6)} className="header-menu-title"><Link to={`/${lang}${item.url}`} onClick={onClick} style={style}>{`${item.label}`}</Link></li>)
                            }
                            </ul>
                        </li>
                        <li className="nav-item header-menu-title" tabIndex="0"><span>{windsurfing.label}</span>
                            <ul className="nav-submenu" style={{height:'auto'}}>
                            {
                                windsurfing.submenu.map(item => <li key={nanoid(6)} className="header-menu-title"><Link to={`/${lang}${item.url}`} onClick={onClick} style={style}>{`${item.label}`}</Link></li>)
                            }
                            </ul>
                        </li>
                        <li className="nav-item header-menu-title" tabIndex="0"><span>{more.label}</span>
                            <ul className="nav-submenu and-more" style={{height:'auto'}}>
                            {
                                more.submenu.map(item => <li key={nanoid(6)} className="header-menu-title"><Link to={`/${lang}${item.url}`} onClick={onClick} style={style}>{`${item.label}`}</Link></li>)
                            }
                            </ul>
                        </li>
                        <li className="nav-item header-menu-title" ><Link to={`/${lang}${contact.url}`} onClick={onClick} style={style}>{contact.label}</Link></li>
                        <li className="nav-item header-menu-title no-ham" style={{paddingRight:10}}><a style={pointer} onClick={()=>changeLang('en')}>EN</a> | <a style={pointer} onClick={()=>{changeLang('de')}}>DE</a> | <a style={pointer} onClick={()=>{changeLang('fr')}}>FR</a></li>
                        <li className="nav-item header-menu-title yes-ham" style={{paddingRight:10}}><a style={pointer} onClick={()=>changeLang('en')}>EN&nbsp;</a> | <a style={pointer} onClick={()=>{changeLang('de')}}>&nbsp;DE&nbsp;</a> | <a style={pointer} onClick={()=>{changeLang('fr')}}>&nbsp;FR&nbsp;</a></li>
                </ul>
                <div className="hamburger" >
                    <label htmlFor="my-check">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </label>
                    
                </div>
            </nav>

        </header>
    )
}

export default withRouter(Header);