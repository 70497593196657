import './Prices.css'
import data from './Prices.yaml'
import { nanoid } from 'nanoid'
import { Fragment, useEffect, useState, useRef} from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import useCurrentLang from '../useCurrentLang';
//import { Popup } from '../Popup'

function Prices({ymlkey, showPrepay, hideFullPriceList, hideTopBookByEmail}){
  //const [open, setOpen] = useState(false);
    const [isPrepay, setPrepay] = useState(false)
    const initialRender = useRef(true);
    const history = useHistory()
    const loc = useCurrentLang()
    const yml = data[loc] || data.en
    const {prepay, prepayToggle, bookbyemail,  pricelist, viewpricelist, fullpricelist, termsinfo} = yml
    const {h1, items} = yml[ymlkey][0]
    const forAni = nanoid(6)
    const onClick =(evt)=> {
      let img = evt.target
      if(img.src.indexOf('_off')>0){ 
        img.src = img.dataset.on
        setPrepay(true)
      }
      else{
        img.src = img.dataset.off
        setPrepay(false)
      }
      
    }
    const pdfFileName = "KiteGlobing_Prices_EUR_2022_2023_oct22.pdf"
    const onClickViewPrices =(evt)=> {
      evt.preventDefault()
      history.push(`/${loc}/pricelist-viewer?pdf=${pdfFileName}`);
    }

    useEffect(()=>{

      if(initialRender.current){
        initialRender.current = false
      }
      else{
        if(showPrepay){
          document.querySelectorAll(`.forAni-${forAni}`).forEach((item, index) => {
            if(index>0){
              item.classList.toggle('run-ani-on'); 
              item.addEventListener('animationend', function(evt){
                item.classList.remove('run-ani-on');
              })
            }
            else{
              item.classList.toggle('run-ani-on-header');
              item.addEventListener('animationend', function(evt){
                item.classList.remove('run-ani-on-header');
              })
            }
            
          })
        }
        
      }

    },[isPrepay])

    return (

      
        <div className="price-container">
              
              <div className="price-main-section">

                {!hideTopBookByEmail && <div className="pricelist">
                  {showPrepay && <div className="pre-pay"><span>{prepay}</span> </div>}
                  <div className="book-by-email"><span dangerouslySetInnerHTML={{ __html: bookbyemail }}></span></div>
                </div>}
                {hideTopBookByEmail && <div>
                  <div><span>&nbsp;</span></div>
                </div>}

                <div className="price-main-section" style={{}}>
                  <h1>{h1}</h1>
                  {showPrepay && <div style={{display:'flex'}}>
                    <span style={{margin:'auto 0px', padding:'0px 6px', color:'#333' , fontWeight:'bold', fontFamily:'Oswald', fontSize:18}}>{prepayToggle}</span>
                    <img style={{cursor:'pointer', width:'44px', height:'auto', margin:'0px 0px'}} src="/img/toggle_off_black_24dp.svg" alt="" 
                    data-on="/img/toggle_on_black_24dp.svg" data-off="/img/toggle_off_black_24dp.svg" 
                    onClick={onClick} />
                  </div>}
                </div> 
        
                {Items(items, isPrepay, forAni)}
                  
                  {/* <div className="priceList price-main-section" style={{padding:20}}>
                    <h2>SafetyTool Insurance</h2>
                    <h2><a onClick={()=>setOpen(true)} style={{color:'blue', cursor:'pointer',fontSize:'smaller', display:'flex',gap:10}}><span>Read more about&nbsp;</span><img style={{width:140, height:'auto'}} src="/img/credits/safetytool-logo.png" alt="SafetyTool logo" width={926} height={210} /></a> </h2>
                    
                  </div>
                  {open ? <Popup text={`SafetyTool`} closePopup={() => setOpen(false)} ></Popup> : null} */}

                {!hideFullPriceList && <><h1>{fullpricelist}</h1>
                <div className="pricelist">
                  <div className="book-info"><a style={{cursor:'pointer',padding:10}} onClick={onClickViewPrices}><span>{viewpricelist}&nbsp;</span><img src="/img/arrow_right_alt_black_24dp.svg" alt="open pdf" /></a></div>
                  <div className="book-info"><a style={{padding:10}} href={`/pdf/${pdfFileName}`} download><span>{pricelist}&nbsp;</span><img src={`/img/file_download_black_24dp.svg`} alt="download pdf" /></a>
                  </div>
                  <div className="terms-info"><span>{termsinfo[0]}<a href={`/${loc}/terms`}>{termsinfo[1]}</a></span></div>
                </div></>}

                {!hideFullPriceList && <div className="pricelist" style={{marginTop:30, marginBottom:30}}>
                  <div className="book-by-email"><span dangerouslySetInnerHTML={{ __html: bookbyemail }}></span></div>
                </div>}
        
              </div>
        </div>
            
    )

}

function Items(items, isPrepay, forAni){

    
    let colIdx = isPrepay ? 2 : 1
    return(
        <>
        {
            items.map(item => <Fragment key={nanoid(6)}>
                <h2>{item.h3}</h2>
                <div className="pricelist">
                  {item.cols.map( col => <Fragment key={nanoid(6)}><div>{col[0]}</div><div className={`forAni-${forAni}`}>{col[colIdx]}</div></Fragment>)}
                  {item.footer.map( row => <div key={nanoid(6)} className={`${row[0]}`}>{row[1]}</div>)}
                </div>
            </Fragment>
            )
        }
        </>
    )

}

export default withRouter(Prices)