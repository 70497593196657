import './Carousel.css'
import { nanoid } from 'nanoid'
import { useState, useEffect } from 'react';

export default function Carousel({imgs, index, close}){
    
    const [startPos, setCarouselPos] = useState(index);

    useEffect(()=>{
        ////if(startPos!=index) setCarouselPos(index)
            // setTimeout(()=>scrollToAnchor(`#item-${index}`), 100)
    },[])

    // const fn = (a)=>{
    //     console.log(a[0].length)
    //     let arrNew = []
    //     let maxlength = a.reduce((prev, curr)=> Math.max(curr.length, prev), 0)
    //     for(let i=0; i<maxlength; i++){
    //         arrNew = [...arrNew, ...a.filter((item, index)=> index>-1?item:null).map(item => item.length> i ? item[i] : null)]
    //     }
    //     console.log("arrNew",arrNew)
    //     return arrNew
    // }
    // let imgarr = fn(imgs)
    // console.log(imgs)
    let imgarr = imgs.filter((item, ind)=>ind===startPos)
    // console.log("LEN",imgarr.length)
    // const scrollToAnchor = (selectedAnchor, ix) => {
    //     // document.querySelector(selectedAnchor).scrollIntoView({
    //     //     behavior: 'smooth', block:'center', inline:'center'
    //     // });
    //     console.log(`scrollTo...${selectedAnchor}`)
    //     console.log(`scrollTo...${ix}`)
    //     // imgarr = imgs.filter((item, ind)=>ind==ix)
    //     setCarouselPos(ix)
    // }
    const scrollToAnchor = (ix) => {
        // console.log(`scrollTo...${ix}`)
        setCarouselPos(ix)
    }

    function CreateDivs(){
        return (
            imgarr.map((item)=><div key={nanoid(6)} className="slide">
            <a onClick={()=>scrollToAnchor(startPos === 0 ? imgs.length-1 : startPos-1)}></a>
                <img id={`item-${index}`} src={`${item}`} alt="" style={{margin:0, outline:'none', border:0,padding:0}} onLoad={(e)=>{

                    let img = e.target
                    const width  = window.innerWidth  || document.documentElement.clientWidth || document.body.clientWidth;
                    const height = window.innerHeight || document.documentElement.clientHeight|| document.body.clientHeight;
                    const wAR = width/height;
                    let ratio = img.naturalWidth / img.naturalHeight
                    let devMW = window.innerHeight * ratio
                    if(devMW>window.innerWidth) devMW = window.innerWidth
                    //console.log(devMW)

                    // if(ratio > 1){  img.style.width="100vw"; img.style.height="auto"; img.style.maxWidth =`${devMW}px`  }
                    // else{ img.style.height="100vh"; img.style.width="auto"; img.style.maxWidth =`${devMW}px`}

                    if(width > img.naturalWidth && height > img.naturalHeight){
                        img.style.width=`${img.naturalWidth}px` ; img.style.maxWidth=`${img.naturalWidth}px` ; img.style.height=`${img.naturalHeight}px`; img.style.maxHeight =`${img.naturalHeight}px`
                    }
                    else{
                        // if(wAR >1 && ratio>1 ){ 
                            if(ratio > wAR){
                                let k = wAR / ratio
                                let newH = height * k;
                                let newW = newH * ratio
                                img.style.width=`${newW}px` ; img.style.height=`${newH}px`; 
                            }
                            else{
                                let newH = height;
                                let newW = newH * ratio
                                img.style.width=`${newW}px` ; img.style.height=`${newH}px`; 
                            }
                            
                        // }
                        // if(wAR >1 && ratio<1 ){ 
                        //     let k = ratio / wAR
                        //     let newH = height ;// img.naturalHeight * k
                        //     let newW = newH * ratio;
                        //     img.style.width=`${newW}px` ; img.style.height=`${newH}px`; 
                        // }
                    }
                    

                }} />
            {/* <a style={{textDecoration:'none', outline:'none', margin:0, border:0, padding:0}} onClick={()=>scrollToAnchor(startPos === imgs.length-1 ? 0 :startPos+1)}></a> */}
            <a onClick={()=>scrollToAnchor(startPos === imgs.length-1 ? 0 :startPos+1)}></a>
            </div>)
            
        )
    }

    return(
        <div className="wrapper" key={nanoid(7)} >
            <div className="close-wrapper">
                <img src="/img/close_black_24dp.svg" alt="close" onClick={close} style={{backgroundColor:'#eee', borderRadius:100, width:46, height:46}} />
                {/* <div>{Date.now()}</div> */}
            </div>
            <div className="carousel" tabIndex="0" >
                {CreateDivs()}
            </div>
            
        </div>
    )
}