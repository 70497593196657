import BtnReadMore from './BtnReadMore/BtnMore'
import useCurrentLang from './useCurrentLang'

export default function YouMightPrefer({caption, url, msg}){

  const loc = useCurrentLang()

  return <section className="section-content">
        <div><h1 style={{textAlign:'center', fontFamily:'Raleway', fontSize:20, margin:20}}>{caption}</h1></div>
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
          <div style={{flex:'0 1 240px', margin:'10px 20px'}}>
              <BtnReadMore to={`/${loc}${url}`}  msg={`${msg}`} />
          </div>
        </div>
      </section>
}