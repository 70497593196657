import './Contact.css';
import data from './Contact.yaml'
import { nanoid } from 'nanoid'
import { Helmet } from 'react-helmet-async';
import { Fragment, useState } from 'react'
import useCurrentLang from '../Components/useCurrentLang';
import PageHeader from '../Components/PageHeader/PageHeader';

function Contact() {

  const [currTime, setCurrTime] = useState(new Date().toLocaleTimeString('en-MU', {timeZone:'Indian/Mauritius'}))
  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  setInterval(()=> setCurrTime(new Date().toLocaleTimeString('en-MU', {timeZone:'Indian/Mauritius'})), 60000)

  const fontBold ={fontWeight:'bold'}
  const aStyle = {textDecoration:'none',color:'blue'}
  const metaDesc = yml.metaDescription || yml.title

  return (
      
    <div>
      <Helmet>
          <title>{yml.title}</title>
          <meta name="description" content={`${metaDesc}`} />
      </Helmet>
      <PageHeader pageTitle={yml.pageTitle}></PageHeader>
      <section className="section-content" style={{paddingBottom:80, marginTop:0}} >
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', width:'100%', margin:'0px auto', padding:'0px 0px', fontFamily:'Raleway'}}>
            <div style={{flex: '1 0 300px'}}>
              <address style={{fontStyle:'normal'}}>
              {
                yml.mainAddress.map( (item,index) => index===0 ? <p key={nanoid(6)} style={fontBold}>{item}</p>: <Fragment key={nanoid(6)}>{item}<br/></Fragment>)
              }
              </address>
              
              <p>&nbsp;</p>
              {
                yml.manager.map( (item,index) => index===0 ? <p key={nanoid(6)} style={fontBold}>{item}</p>: <Fragment key={nanoid(6)}>{item}<br/></Fragment>)
              }
              <p>&nbsp;</p>
              {
                yml.openingHours.map( (item,index) => index===0 ? <p key={nanoid(6)} style={fontBold}>{item}</p>: <Fragment key={nanoid(6)}>{item}<br/></Fragment>)
              }
              <p>{yml.currentTime} {currTime}</p>
              <p>&nbsp;</p>
              
            </div>
            <div style={{flex: '1 0 300px'}}>
              {
                yml.offices.map( (item,index) => index % 2 ===0 ? <p key={nanoid(6)} style={fontBold}>{item}</p>: <Fragment key={nanoid(6)}>{item}<br/></Fragment>)
              }
              <p style={{fontWeight:'bold'}}>Email</p>
              <p style={{}}><a href={`mailto:${ yml.email }`} style={aStyle}>{ yml.email }</a></p>
              <p style={{fontWeight:'bold'}}>Map</p>
              <p style={{}}><a href={`${ yml.map }`} style={aStyle} target="_blank" rel="noopener noreferrer">{ yml.map }</a></p>
              <p style={{fontWeight:'bold'}}>WhatsApp</p>
              <p>
                  {/* <a href="https://www.facebook.com/KiteGlobing-217243858294399/" target="_blank" rel="noopener noreferrer"><img className="contact-social-img" src="/img/facebook.png" alt="KiteGlobing facebook" /></a> */}
                  {/* <a href="https://www.instagram.com/kiteglobing/" target="_blank" rel="noopener noreferrer"><img className="contact-social-img" src="/img/instagram.png" alt="KiteGlobing instagram" /></a> */}
                  <a href="https://wa.me/23057175348" target="_blank" rel="noopener noreferrer"><img className="contact-social-img" src="/img/whatsapp.webp" alt="KiteGlobing whatsapp" /></a>
              </p>
            </div>
            <div style={{flex: '2 0 300px'}}>
              <img src={`/img/${yml.image}`} style={{width:'100%', height:'auto'}} alt={`${yml.imageAlt}`} />
            </div>
        </div>
        
      </section>
    </div>
  );
}

export default Contact;