import './Components/BtnReadMore/BtnMore.css'
import data from './ConsentBanner.yaml'
import useCurrentLang from './Components/useCurrentLang';
import { NavLink } from "react-router-dom";

function ConsentBanner({setCookie}){

    const loc = useCurrentLang()
    const yml = data[loc] || data.en

    let bannerStyle = {
        position:"fixed", 
        bottom:0, 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center',
        padding:'10px 20px 10px 20px', 
        textAlign:'center', 
        height:150, 
        width: '100%', 
        color:'#000', 
        backgroundColor:'rgb(236,236,236, 1)',
        padding:5,
        border: 5
    }

    return (
        <div style={bannerStyle}>
            <div>
                <div>{yml.text[0]}<NavLink to={`/${loc}/terms`}>{yml.text[1]}</NavLink>{yml.text[2]}<NavLink to={`/${loc}/privacy-policy`}>{yml.text[3]}</NavLink>
                {!!yml.text[4] && yml.text[4]}.
                </div>
                <button className="btn-read-more" onClick={setCookie}>
                    <span style={{textAlign: 'center', margin:'auto'}}>{yml.btn}</span>
                </button>
            </div> 
        </div>

    )
}

export default ConsentBanner;