import React from "react";
import useCurrentLang from './useCurrentLang';
import data from './Popup.yaml'
import { processItem } from '../Components/others'
import "./Popup.css";

export const Popup = ({ text, closePopup, children }) => {

    // const processItem = item => {
    //    const regexpNames = /(?<text>(.*)) {{(?<link>(.*))}}(?<rest>(.*))/gm;
    //    const r1 = item.match(regexpNames)
    //    if(r1==null){ return item}
    //    return <>
    //     {Array.from(item.matchAll(regexpNames)).map( a => <>{a.groups.text} <span dangerouslySetInnerHTML={{ __html: a.groups.link }}></span>{a.groups.rest}</>)}
    //    </>
    // }
    const loc = useCurrentLang();
    const yml = data[loc] || data.en;
    const popuptxt = !!children ? yml[children] : yml.popup

    const svgStyle ={
        stroke:'white',
        strokeWidth: 4,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: 'none'
    }

    return (
        <div className="popup-container">
            <div className="popup-body">
                <button className="popup-body-button" onClick={closePopup} >
                    <svg viewBox="0 0 38 38" width="38" height="38" style={svgStyle}><line x1="6" y1="6" x2="32" y2="32"></line><line x1="32" y1="6" x2="6" y2="32"></line></svg>
                </button>
                <h1 style={{padding:0, marginTop:0, marginBottom:0, paddingRight:40,display:'flex', justifyContent:'flex-end'}}>
                    <a style={{textDecoration:'none', color:'transparent'}} href='http://www.safetytool.de' target="_blank"><img style={{width:160, height:'auto'}} src="/img/credits/safetytool-logo.png" alt="SafetyTool logo" width={926} height={210} /></a>
                </h1>
                {/* <div className="popup-body-children">{processItem(children)}</div> */}
                <div className="popup-body-children" style={{overflowY:'auto'}}>{processItem(popuptxt)}</div>
            </div>
        </div>
    );
};