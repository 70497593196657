import './BtnMore.css'
import { Link } from "react-router-dom";

function BtnReadMore({to, msg}){

    const text = msg || 'Read More'
    return (
        // 
        <>
        <Link className="btn-read-more" to={to} style={{ textDecoration: 'none', color:'inherit' , width:'auto'}}>
            <span style={{textAlign:'center', margin:'auto auto'}}>{text}</span>
        </Link>
        {/* <button className="btn-read-more"><span style={{textAlign:'center', margin:'auto auto'}}>Read More</span></button> */}
        </>
    )
}

export default BtnReadMore;