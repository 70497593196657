import './App.css';
import Contact from './Contact/Contact'
import Home from './Home'
import Header from './Header'
import Footer from './Footer'
import WatersportCenter from './WatersportCenter/WatersportCenter'
import KitesurfCenterBelOmbre from './WatersportCenter/KitesurfCenter-belombre'
import Windsurfing from './Windsurfing/Windsurfing'
import Kitesurfing from './Kitesurfing/Kitesurfing'
import Downwinder from './Kitesurfing/Downwinder'
import BoatTrips from './More/BoatTrips'
import Wakeboarding from './More/Wakeboarding'
import SUP from './More/SUP'
import EPedalo from './More/EPedalo';
import Surfing from './More/Surfing'
import Wingsurfing from './More/Wingsurfing'
import StorageRescue from './Kitesurfing/StorageRescue'
import Press from './Press/Press'
import OurVideo from './Video/Video'
import JuniorAcademy from './More/JuniorAcademy';
import Sailing from './More/Sailing';
import Events from './More/Events'
import Accomodation from './More/Accomodation'
import ConsentBanner from './ConsentBanner';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import {ScrollToTopClassic as ScrollToTop} from './ScrollToTop'
// import {ScrollToTopHook as ScrollToTop} from './ScrollToTop'
import Credits from './Components/Altro/Credits'
import { HelmetProvider } from 'react-helmet-async';
import { nanoid } from 'nanoid'
import { useState, useMemo, useEffect } from 'react';
import PricelistViewer from './Components/PricelistViewer/PricelistViewer';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Terms from './Terms/Terms';
import {LangContext} from './Components/useCurrentLang'


function getCurrentLocationLang(location){
  const rxM0 = /\/(en|fr|de)\/?$/ig
  const rxM1 = /\/(en|fr|de)\//ig
  const array0 = [...location.pathname.matchAll(rxM0)];
  const array1 = [...location.pathname.matchAll(rxM1)];
  let currlang = (array0.length && array0[0][1].toLowerCase()) || (array1.length && array1[0][1].toLowerCase())
  currlang = currlang || 'en'
  return currlang 
}



function App() {

  const [lang, setLang] = useState(getCurrentLocationLang(window.location));
  const [consentBanner, setConsent] = useState(false);
  const value = useMemo(
    () => ({ lang, setLang }), 
    [lang]
  );

  useEffect(()=>{

    if(window){
      if(document.cookie.split(';').filter(c => c.includes('kiteglobingconsent')).length){
          // cookie is set already
      }
      else{
          setConsent(true)
      }
    }

  })

  function setConsentCookie(){
    let newvalue = `kiteglobingconsent=ok; path=/; expires=${new Date(Date.now()+(180*24*60*60000)).toUTCString()}`
    document.cookie = newvalue
    setConsent(false)
  }

  // const pathRE = new RegExp('^(?!.*(\/en\/|\/de\/)).*$')
  const pathRE = new RegExp('^(?!.*(\/en\/|\/de\/|\/fr\/)).*$')

  return (
    <div>
      
      <Router>
      <LangContext.Provider value={value}>
      <HelmetProvider>
        <ScrollToTop />
        <Header  mykey={nanoid(6)} />
        <Switch>
          <Route path="/:lang(en|de|fr)?" exact={true}>
            <Home />
          </Route>
          <Route path={pathRE} >
            {
            ({ location }) => { 
              //console.log(location)
              if(location.pathname.length < 5) return <Redirect to={`/en`} />
              const slugs = location.pathname.split('/')
              //console.log(slugs)
              let x = 0
              let y = 1
              if(slugs[0].length===0){x++; y++;}
              if(slugs[x].length===2) return <Redirect to={`/en/${slugs[y]}`} />
              return <Redirect to={`/en${location.pathname}`} />
            }
            }
          </Route>
          <Route path="/:lang(en|de|fr)?/contact">
            <Contact />
          </Route>
          <Route path="/:lang(en|de|fr)?/kitesurf-center">
            <WatersportCenter />
          </Route>
          <Route path="/:lang(en|de|fr)?/center-bel-ombre">
            <KitesurfCenterBelOmbre />
          </Route>
          <Route path="/:lang(en|de|fr)?/kitesurf-lesson">
            <Kitesurfing type={`lesson`} />
          </Route>
          <Route path="/:lang(en|de|fr)?/kitesurf-rental">
            <Kitesurfing type={`rental`} />
          </Route>
          <Route path="/:lang(en|de|fr)?/storage-rescue">
            <StorageRescue />
          </Route>
          <Route path="/:lang(en|de|fr)?/kitesurf-storage-rescue">
            <StorageRescue />
          </Route>
          <Route path="/:lang(en|de|fr)?/kitesurf-downwinder">
            <Downwinder />
          </Route>
          <Route path="/:lang(en|de|fr)?/windsurf-lesson">
            <Windsurfing type={`lesson`} />
          </Route>
          <Route path="/:lang(en|de|fr)?/windsurf-rental">
            <Windsurfing type={`rental`} />
          </Route>
          <Route path="/:lang(en|de|fr)?/boat-and-dolphin-tours">
            <BoatTrips />
          </Route>
          <Route path="/:lang(en|de|fr)?/wakeboarding">
            <Wakeboarding />
          </Route>
          <Route path="/:lang(en|de|fr)?/stand-up-paddling">
            <SUP />
          </Route>
          <Route path="/:lang(en|de|fr)?/e-pedalo">
            <EPedalo />
          </Route>
          <Route path="/:lang(en|de|fr)?/surfing">
            <Surfing />
          </Route>
          <Route path="/:lang(en|de|fr)?/wingsurfing">
            <Wingsurfing />
          </Route>
          <Route path="/:lang(en|de|fr)?/villas-hotels-apartments">
            <Accomodation />
          </Route>
          <Route path="/:lang(en|de|fr)?/press">
            <Press />
          </Route>
          <Route path="/:lang(en|de|fr)?/pricelist-viewer">
            <PricelistViewer />
          </Route>
          <Route path="/:lang(en|de|fr)?/our-video">
            <OurVideo />
          </Route>
          <Route path="/:lang(en|de|fr)?/sailing">
            <Sailing />
          </Route>
          <Route path="/:lang(en|de|fr)?/junior-academy">
            <JuniorAcademy />
          </Route>
          <Route path="/:lang(en|de|fr)?/events">
            <Events />
          </Route>
          <Route path="/:lang(en|de|fr)?/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/:lang(en|de|fr)?/terms">
            <Terms />
          </Route>
          <Route path="/:lang(en|de|fr)?">
            <Home />
          </Route>
        </Switch>
        <Credits/>
        <Footer />
        {consentBanner && <ConsentBanner setCookie={setConsentCookie}/>}
      </HelmetProvider>
      </LangContext.Provider>
      </Router>
      
    </div>
  );
}

export default App;
