import data from './KitesurfCenter-belombre.yaml'
import { nanoid } from 'nanoid'
import { Helmet } from 'react-helmet-async'
import useCurrentLang from '../Components/useCurrentLang';
import PageHeader from '../Components/PageHeader/PageHeader';

function KitesurfCenterBelOmbre({locale}) {

  const loc = useCurrentLang()
  const yml = data[loc] || data.en
  
  return (
    <div style={{textAlign:'left'}}>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${yml.title}`} />
            
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        
        <section className="section-content section-content-first" >
          <div className="kite-center" style={{alignItems: 'center', flexDirection:'column'}}>
            <div style={{flex:'1 0 100%'}}>
              <img width="100%" height="auto" style={{margin:'0px auto', display:'block'}} src={`/img/${yml.image}`} alt={yml.imageAlt} />
            </div>
          </div>
        </section>

        <section className="section-content" style={{marginTop:0}}>
          <div className="kite-center" style={{maxWidth:1280, margin:'0px auto auto auto'}}>
            <div style={{flex:'1 0 100%'}}>
              <div className="kite-content">
                <h1 style={{textAlign:'center', fontSize:30}}>{yml.content.heading}</h1>
                <p>&nbsp;</p>
                {yml.content.text.map( item => <p key={nanoid(6)}>{item}</p>)} 
                {yml.content.headings.map( item => <div key={nanoid(6)}>
                  <h2 style={{textAlign:'center', fontSize:30}}>{item.heading}</h2>
                  {item.text.map( txt => <p key={nanoid(6)}>{txt}</p>)}
                </div>) }
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section-content" style={{marginTop:0}}>
          <div className="kite-content"><h5 style={{textAlign:'center', fontFamily:'Raleway', fontSize:20, margin:20}}>You might prefer and visit:</h5></div>
          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
            <div style={{flex:'0 1 240px', margin:'10px 20px'}}><BtnReadMore to="/center-Palmar"  msg="Palmar Center" /></div>
            <div style={{flex:'0 1 240px', margin:'10px 20px'}}><BtnReadMore to="/center-prince-maurice" msg="Prince Maurice Center" /></div>
          </div>
        </section> */}

        <section className="section-content" style={{position:'relative', display:'block'}}>
          <div className="kite-videos">
            <div>
            <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/gExgXquFHT4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
              <div>
              <div className="video-background" style={{height:500}}>
                <iframe style={{frameBorder:0}} width="100%" height="100%" src="https://www.youtube.com/embed/Koy4qTE3yeY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
              </div>
            
          </div>
        </section>

        
        {/* <section className="section-content">
         {usePhGallery(yml.gallery)}
        </section> */}


    </div>
  );
}

export default KitesurfCenterBelOmbre;