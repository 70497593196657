import data from './Downwinder.yaml'
import { Helmet } from 'react-helmet-async'
import PageHeader from '../Components/PageHeader/PageHeader';
import useCurrentLang from '../Components/useCurrentLang';
import { PageSectionImg, PageSectionContent } from '../Components/PageSections/PageSections';

function Downwinder() {

  const loc = useCurrentLang();
  const yml = data[loc] || data.en;
  const metaDesc = yml.metaDescription || yml.title;
  
  return (
      
    <div>
        <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
        </Helmet>
        <PageHeader pageTitle={yml.pageTitle}></PageHeader>
        <PageSectionImg image={yml.image} imageAlt={yml.imageAlt} imgStyle={{maxWidth:1200}} />
        <PageSectionContent content={yml.content} />

    </div>
  );
}

export default Downwinder;